import React, {
  forwardRef, useCallback, useImperativeHandle, useRef, useState,
} from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';

import clone from 'lodash/clone';
import pick from 'lodash/pick';
import { apiUrls } from '../../../../../../api/constants';
import SuccessMessages from '../../../../../../lib/successMessages';
import useRedirect from '../../../../../../hooks/useRedirect';

import Form from '../../../../../../components/Form';
import Input from '../../../../../../components/Form/Input';
import Notification from '../../../../../../components/Notification';
import DatePicker from '../../../../../../components/Form/DatePicker';
import PayerGridAutoComplete from '../../../../../../wiredComponents/PayerGridAutoComplete';
import Modals from '../../../../../../components/Modal';

import LineItemTable from '../../../../../Claims/withoutTabs/Remittance/InsurancePayment/LineItemsTable';

import WiredSelect from '../../../../../../wiredComponents/Select/selectBoxV2';
import RequestNewPayerForm from '../../../../../../wiredComponents/Modal/Payer/component/RequestNewPayer';
import Events from '../../../../../../lib/events';
import formFieldValueParser from '../../../../../../lib/formFieldValuesParser';
import SelectBox from '../../../../../../components/Form/SelectBox';

const filteredLineItems = (lineItems) => {
  const filteredArray = [];
  lineItems.forEach((item) => {
    if (!item?.isPosted && item?.isEdited) {
      const updatedPaymentObject = {
        ...item,
        coInsurance: item?.coIns,
        value: item?.balance,
        deductible: item?.deduct,
        adjustment: item?.adjust,
        applied: item?.paid || item?.applied,
      };
      const nonEmptyStrings = Object.keys(
        updatedPaymentObject,
      ).filter((key) => (
        updatedPaymentObject[key] !== ''
         && updatedPaymentObject[key] !== undefined
         && updatedPaymentObject[key] !== null));
      filteredArray.push(pick(updatedPaymentObject, nonEmptyStrings));
    }
  });
  return filteredArray;
};

const initialDataParser = (values) => {
  const { detailsLineItem } = values || {};
  const { financeDetails } = detailsLineItem || {};
  financeDetails.checkAmount = `${financeDetails?.checkAmount ?? 0}`;
  delete detailsLineItem.financeDetails;
  const clonedValues = { ...detailsLineItem, ...financeDetails, amount: `${detailsLineItem?.amount ?? 0}` };
  return formFieldValueParser(clonedValues, {
    date: [
      'effectiveDate',
      'expirationDate',
      'checkDate',
    ],
  });
};

const InsurancePaymentDetails = forwardRef((props, ref) => {
  const {
    labels, data = {}, handleAddEncounter, form,
    closeModal, setAddInsurancePayment, financialId,
    isEditInsurancePayment, claimDetails, transactionTypeId,
  } = props;

  const container = useRef();

  const parser = useCallback((formValues) => ({
    ...formValues,
    effectiveDate: moment(formValues.effectiveDate).format('MM-DD-YYYY 00:00:00'),
    checkDate: moment(formValues.checkDate).format('MM-DD-YYYY 00:00:00'),
  }), []);

  const [lineItemData, setLineItemData] = useState([]);
  const [isNextPayerResponsibility, setNextPayerResponsibility] = useState(false);
  const { params: { id: patientId } } = useRedirect();
  const [isPayerModalVisible, setAddPayerVisibility] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});

  const [formData, setFormData] = useState({});

  const handleOnBack = useCallback(() => handleAddEncounter(false), [handleAddEncounter]);

  const onSelectProvider = useCallback((_, _all) => {
    if (_all) {
      const { item } = _all;
      setSelectedProvider({ providerId: item?.providerId, providerName: item?.providerName });
    }
  }, []);

  const onSelectLocation = useCallback((locationId, _all) => {
    if (_all) {
      const { item } = _all;
      setSelectedLocation({ locationId: item?.locationId, locationName: item?.locationName });
    }
  }, []);

  useImperativeHandle(ref, () => ({

    submitPaymentForm() {
      const isDenialReasonNotSelected = lineItemData?.some((item) => (
        parseFloat((item?.approved), 10) === 0) && !item?.denialReasonId
        );
      if (isDenialReasonNotSelected) {
        Notification({ message: 'Please select denial reason' });
        return;
      }
      const isApprovedLessThanPaid = filteredLineItems(lineItemData).every((lineItem) => {
        if ((parseFloat(lineItem?.paid) > parseFloat(lineItem?.approved))
         || (!parseFloat(lineItem.approved || 0) && parseFloat(lineItem?.paid || 0))) return false;
        return true;
      });
      if (!isApprovedLessThanPaid) {
        Notification({ message: 'Approved amount should be greater than paid amount' });
      } else {
        const sum = filteredLineItems(lineItemData).reduce(
          (accumulator, currentValue) => accumulator + parseFloat((currentValue?.paid) ?? 0),
          0,
        );
        if (parseFloat(form.getFieldValue('amount'))?.toFixed(2) !== sum?.toFixed(2)) {
          Notification({ message: 'Paid amount should be equal to insurance amount' });
        } else form.submit();
      }
    },
  }));

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({
        message: SuccessMessages.INSURANCE_PAYMENT_ADDED_SUCCESSFULLY,
        success: true,
      });
      Events.trigger('refetch-balance-sheet');
      Events.trigger('refetch-charge-table');
      closeModal();
      setAddInsurancePayment(false);
      if (financialId) Events.trigger(`reFetchFinancialDetail-${financialId}`);
    }
  }, [closeModal, financialId, setAddInsurancePayment]);

  const toggleAddPayerModal = useCallback(() => {
    setAddPayerVisibility(!isPayerModalVisible);
  }, [isPayerModalVisible]);

  const onGetResponseComplete = useCallback((formValues) => {
    setFormData(clone(formValues));
    setSelectedProvider({
      providerId: formValues?.providerId,
      providerName: formValues?.providerName,
    });
    setSelectedLocation({
      locationId: formValues?.locationId,
      locationName: formValues?.locationName,
    });
  }, []);

  return (
    <>
      <div ref={container} className="pannel back-pannel mr-top-20">
        {!financialId && (
        <div className="pannel-heading">
          <p className="back-arrow" id="patients_financial_detail_goBack" onClick={handleOnBack} role="presentation">
            {labels.get('buttons.goBack')}
          </p>
        </div>
        )}
        <div className="pannel-body financial-insurance-payment-form">
          <Form
            form={form}
            initialData={!financialId && {
              providerId: data?.providerId,
              locationId: data?.locationId,
              payerId: data?.insurancePayerId,
              providerName: data?.providerName,
              locationName: data?.locationName,
            }}
            section
            parser={parser}
            initialDataParser={financialId && initialDataParser}
            extraData={{
              LineItems: filteredLineItems(lineItemData),
              patientId,
              PaymentSource: 'OverTheCounter',
              PaymentMethod: 'Manual',
              paymentId: financialId,
              existingClaimId: data?.claimId || claimDetails?.prevClaimId,
              existingClaimNo: data?.claimNo || claimDetails?.prevClaimNo,
              method: 'POST',
              transactionTypeId,
              IsAnyFurtherPayerResponsibility: isNextPayerResponsibility,
              workListId: financialId ? lineItemData?.[0]?.workListId : null,
            }}
            getUrl={financialId && `${apiUrls.GET_FINANCIAL_DETAIL}?FinanceId=${financialId}&IsEditPayment=true`}
            isUpdate={!!financialId}
            onRequestComplete={onRequestComplete}
            formId={financialId ? 'insurance-payment-update'
              : 'insurance-payment-add'}
            url={financialId ? apiUrls.EDIT_INSURANCE_PAYMENT
              : apiUrls.ADD_FINANCIAL_INSURANCE_PAYMENT}
            onGetResponseComplete={onGetResponseComplete}
          >
            <div className="modal-content-weapper">
              <div className="modal-from-feilds">
                <div className="feild-row">
                  <Row gutter={24}>
                    <Col>
                      <DatePicker
                        label={labels.get('labels.effectiveDate')}
                        name="effectiveDate"
                        required
                        disabled={financialId}
                        datePickerProps={{ getPopupContainer: (trigger) => trigger?.parentNode }}
                        dataTestId="effectiveDate"
                      />
                      <Input
                        label={labels.get('labels.amount')}
                        name="amount"
                        required
                        numberWithDecimal
                        maxValueLength={11}
                        disabled={financialId}
                        dataTestId="amount"
                      />
                      <div className="payer-wrapper with-edit-option">
                        <div className="add-input">
                          <PayerGridAutoComplete
                            label={labels.get('modalLabels.payer')}
                            name="payerId"
                            required
                            nameAccessor="name"
                            valueAccessor="insurancePayerId"
                            labelSpan="10"
                            inputSpan="14"
                            dropdownMatchSelectWidth={false}
                            initialValue={(data?.insurancePayerName && data?.insurancePayerId && {
                              name: data?.insurancePayerName,
                              value: data?.insurancePayerId,
                            }) || (formData?.payerId && formData?.payerName && {
                              name: formData?.payerName,
                              value: formData?.payerId,
                            })}
                            disabled={financialId}
                            selectProps={{ getPopupContainer: (trigger) => trigger?.parentNode }}
                            dataTestId="payerId"
                          />
                          <span
                            className="add-icon sprite-img-before"
                            onClick={toggleAddPayerModal}
                            role="presentation"
                          />
                        </div>
                      </div>
                      <Input
                        label={labels.get('labels.batchIdentifier')}
                        name="batchIdentifier"
                        maxValueLength={25}
                        disabled={financialId}
                        dataTestId="batchIdentifier"
                      />
                      <Input
                        label={labels.get('labels.payerClaimNumber')}
                        maxValueLength={25}
                        name="payerClaimNumber"
                        disabled={financialId}
                        dataTestId="payerClaimNumber"
                      />
                    </Col>
                    <Col>
                      <Input
                        label={labels.get('labels.checkAmount')}
                        name="checkAmount"
                        required
                        numberWithDecimal
                        maxValueLength={11}
                        disabled={financialId}
                        dataTestId="checkAmount"
                      />
                      <DatePicker
                        label={labels.get('labels.checkDate')}
                        name="checkDate"
                        required
                        disabled={financialId}
                        datePickerProps={{ getPopupContainer: (trigger) => trigger?.parentNode }}
                        dataTestId="checkDate"
                      />
                      <Input
                        label={labels.get('labels.checkNumber')}
                        name="checkNumber"
                        required
                        alphaNumericOnly
                        maxValueLength={25}
                        disabled={financialId}
                        dataTestId="checkNumber"
                      />
                      <WiredSelect
                        name="providerId"
                        id="provider"
                        url={apiUrls.SCHEDULAR_PROVIDER_DROPDOWN}
                        label={labels.get('labels.provider')}
                        nameAccessor="providerName"
                        valueAccessor="providerId"
                        selectProps={{
                          showSearch: true,
                          getPopupContainer: (trigger) => trigger?.parentNode,
                        }}
                        params={{
                          LocationId: selectedLocation?.locationId,
                          PageSize: 1000,
                        }}
                        labelSpan="10"
                        inputSpan="14"
                        Component={SelectBox}
                        required
                        disabled={financialId}
                        onChange={onSelectProvider}
                        initialValue={financialId && formData?.providerId
                          && formData?.providerName && {
                          name: formData?.providerName,
                          value: formData?.providerId,
                        }}
                        inActiveOption={{
                          providerName: selectedProvider?.providerName,
                          providerId: selectedProvider?.providerId,
                        }}
                        dataTestId="provider"
                      />
                      <WiredSelect
                        name="locationId"
                        id="location"
                        labelSpan="10"
                        inputSpan="14"
                        url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
                        label={labels.get('labels.location')}
                        nameAccessor="locationName"
                        valueAccessor="locationId"
                        Component={SelectBox}
                        params={{
                          ProviderId: selectedProvider?.providerId,
                          PageSize: 1000,
                        }}
                        selectProps={{
                          showSearch: true,
                          getPopupContainer: (trigger) => trigger?.parentNode,
                        }}
                        onChange={onSelectLocation}
                        required
                        disabled={financialId}
                        dataTestId="location"
                        inActiveOption={{
                          locationName: selectedLocation?.locationName,
                          locationId: selectedLocation?.locationId,
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="modal-content-area">
                {!isEditInsurancePayment && (
                <div className="feild-row">
                  <div className="claim-head-detail">
                    <div>
                      <span>
                        {labels.get('labels.number')}
                        :
                      </span>
                      <span style={{ marginLeft: '10px' }}>{data?.claimNo}</span>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                      <span>
                        {labels.get('labels.date')}
                        :
                      </span>
                      <span style={{ marginLeft: '10px' }}>
                        {`${data?.billingServiceLineItemCreatedDate?.month}/${data?.billingServiceLineItemCreatedDate?.day}/${data?.billingServiceLineItemCreatedDate?.year}`}
                      </span>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                      <span>
                        {labels.get('labels.amount')}
                        :
                      </span>
                      <span style={{ marginLeft: '10px' }}>{data?.amount}</span>
                    </div>
                  </div>
                </div>
                )}
                <div className="financial-insurance-payment">
                  <LineItemTable
                    claimId={data?.claimId || claimDetails?.prevClaimId}
                    nextPayerClaimId={data?.claimId || claimDetails?.claimId}
                    labels={labels}
                    onDataChange={setLineItemData}
                    setNextPayerResponsibility={setNextPayerResponsibility}
                    form={form}
                    container={container}
                    notFilterServiceLineItems
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Modals
        destroyOnClose
        visible={isPayerModalVisible}
        title={labels.get('titles.requestNewPayer')}
        toggleModal={toggleAddPayerModal}
        footer={null}
      >
        <RequestNewPayerForm toggleModal={toggleAddPayerModal} />
      </Modals>
    </>
  );
});

export default React.memo(InsurancePaymentDetails);

