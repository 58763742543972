import React from 'react';

import TimeCell from '../../../../../../components/Cells/TimeCell';
import Icon from '../../../../../../components/Icon';
import iconNames from '../../../../../../lib/iconNames';
import Tooltip from '../../../../../../components/Tooltip';

const outboxColumns = (labels) => [
  {
    Header: labels.get('tableColumns.patientName'),
    accessor: 'patientName',
    sort: true,
    Footer: ({ totalCount }) => <span>{`${totalCount} Statements`}</span>,
  },
  {
    Header: labels.get('tableColumns.method'),
    accessor: 'methodName',
  },
  {
    Header: labels.get('tableColumns.statementDate'),
    accessor: 'statementDate',
    Cell: ({ row: { original } }) => <TimeCell value={original?.createdOn} />,
  },
  {
    Header: labels.get('tableColumns.currentAmount'),
    accessor: 'currentAmount',
    Cell: ({ row: { original: { currentAmount } } }) => (
      (currentAmount || 0).toFixed(2)
    ),
    className: 'text-align-right',
  },
  {
    Header: labels.get('tableColumns.pastDueAmount'),
    accessor: 'pastDueAmount',
    Cell: ({ row: { original: { pastDueAmount } } }) => (
      parseFloat(pastDueAmount || 0).toFixed(2)
    ),
    className: 'text-align-right',
    fixWidth: 190,
  },
  {
    Header: labels.get('tableColumns.totalAmount'),
    accessor: 'totalAmount',
    Cell: ({ row: { original: { totalAmount } } }) => (
      parseFloat(totalAmount || 0).toFixed(2)
    ),
    className: 'text-align-right',
    Footer: ({ otherTableData: { totalAmount } }) => <span className="inline-right">{totalAmount}</span>,
    fixWidth: 190,
  },
  {
    Header: 'Downloaded',
    accessor: 'isDownloaded',
    Cell: ({ row: { original: { isDownloaded } } }) => (
      <span className="flex justify-content-center">
        {isDownloaded && (
          <Tooltip placement="right" title="Downloaded">
            {' '}
            <Icon name={iconNames.checkOutlined} color="green" />
          </Tooltip>
        ) }
      </span>
    ),
    fixWidth: '60',
  },
];

export default outboxColumns;
