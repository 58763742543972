import React, { useCallback, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import TimeCell from '../../../../../../components/Cells/TimeCell';
import Icon from '../../../../../../components/Icon';
import iconNames from '../../../../../../lib/iconNames';
import useCRUD from '../../../../../../hooks/useCRUD';
import { apiUrls } from '../../../../../../api/constants';

const columns = (labels) => [
  {
    Header: labels.get('tableColumns.statementDate'),
    accessor: 'submittedDate',
    Cell: ({ row: { original } }) => (
      <TimeCell value={original?.submittedDate} />
    ),
    sort: true,
  },
  {
    Header: labels.get('tableColumns.status'),
    accessor: 'statusName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.method'),
    accessor: 'methodName',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.amount'),
    accessor: 'totalAmount',
    sort: true,
  },
  {
    Header: labels.get('tableColumns.sentToWaystar'),
    accessor: 'sentToWaystar',
    Cell: ({ row: { original: { sentToWaystar } } }) => (sentToWaystar
      ? <span>Sent to Waystar</span> : <span>Not Sent to Waystar</span>),
  },
  {
    Header: 'Print',
    accessor: '',
    Cell: ({ row: { original: { statusName, statementId } } }) => {
      const viewPanel = useRef(null);

      const [,,,
        requestStatement,
      ] = useCRUD({
        id: `patient-statement-detail-${statementId}`,
        url: apiUrls.STATEMENTS_DETAILS,
        type: 'read',
      });
      const [
        statementHtml,,,
        getStatementHtml,
        clearStatementHtmlResponse,
      ] = useCRUD({
        id: 'get-patient-statement-html',
        url: apiUrls.STATEMENTS_PDF_PREVIEW,
        type: 'read',
      });

      const handleHtmlPrint = useReactToPrint({
        content: () => viewPanel.current,
        copyStyles: false,
      });

      useEffect(() => {
        if (statementHtml && viewPanel.current) {
          viewPanel.current.innerHTML = statementHtml;
          handleHtmlPrint();
          clearStatementHtmlResponse(true);
        }
      }, [statementHtml]);

      useEffect(() => {
        if (statementId) requestStatement({ statementId });
      }, [statementId]);

      const onPrint = useCallback(() => {
        getStatementHtml({}, `?StatementId=${parseInt(statementId, 10)}`);
      }, [getStatementHtml, statementId]);

      return (
        <div className={!statusName ? 'backColor' : 'noBackColor'}>
          {statusName === 'Submitted' && (
            <div className="cursor-pointer">
              <Icon name={iconNames?.printFill} onClick={onPrint} />
            </div>
          )}
          <div className="display-none" ref={viewPanel} />
        </div>
      );
    },
  },
];

export default columns;
