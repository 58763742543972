import React, {
  useCallback, useState, useEffect, useMemo, useRef,
} from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
import difference from 'lodash/difference';
import { Menu } from 'antd';

import debounce from 'lodash/debounce';
import Button from '../../../../../components/Button';
import Select from '../../../../../wiredComponents/Enum/Select';

import { apiUrls } from '../../../../../api/constants';
import {
  listId, UiRoutes, enums as enumConstants, enumMasterCode,
} from '../../../../../lib/constants';
import rights from '../../../../../lib/rights';

import withQuery from '../../../../../hoc/withQuery/withQuery';

import useRights from '../../../../../hooks/useRights';
import useTabLink from '../../../../../hooks/useTabLink';
import useRedirect from '../../../../../hooks/useRedirect';
import useMasterId from '../../../../../hooks/useMasterId';
import Loader from '../../../../../components/WidgetLoader';

import FilterManager from '../../../../../components/FilterManager';
import FilterComponents from '../../../../../components/FilterComponents';

import ErrorPopUp from '../../../Components/ErrorPopup';

import errorColumns from './Components/errorColumns';
import readyColumns from './Components/readyColumns';
import clearingHouseColumns from './Components/clearingHouseColumns';
import DateFilter from '../../../../../components/DateFilter';
import useCRUD from '../../../../../hooks/useCRUD';
import Notification from '../../../../../components/Notification';
import SuccessMessages from '../../../../../lib/successMessages';
import TableWrapper from './TableWrapper';
import Icon from '../../../../../components/Icon';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import MassRebill from '../Modal/MassRebill';
import SelectBox from '../../../../../components/SelectBox';
import ErrorMessages from '../../../../../lib/errorMessages';
import createQueryParams from '../../../../../lib/createQueryParams';
import waystarRejectedColumns from './Components/waystarRejectedColumns';
import sendToWaystarInProgressColumns from './Components/sendToWaystarInProgressColumns';

const initialSort = [{ id: 'patientName', desc: false }];

const TypeFilters = [
  {
    placeholder: 'Ready',
    name: 'ready',
    id: 'claims_ready',
  },
  {
    placeholder: 'Error',
    name: 'error',
    id: 'claims_error',
  },
  {
    placeholder: 'Outbox',
    name: 'clearingHouse',
    id: 'claims_clearing_house',
  },
  {
    placeholder: 'Waystar in Progress',
    name: 'sendtowaystarinprogress',
    id: 'send_to_waystar_in_progress',
  },
  {
    placeholder: 'Waystar Rejected',
    name: 'clearingHouseRejection',
    id: 'claims_waystar_rejected',
  },
];

const TypeFilterCollection = FilterComponents([
  {
    type: 'enumSelect',
    filterProps: {
      placeholder: 'Select An Option',
      id: 'claims_filter',
      name: 'deliveryMethodId',
      enumName: enumConstants.SUBMISSION_METHOD,
      style: {
        minWidth: 120,
      },
    },
  },
]);

const options = [
  { name: 'Print with Form', value: 'print with form' },
  { name: 'Print without Form', value: 'print without form' },
  { name: 'Mark as Submitted', value: 'mark as submitted' },
  { name: 'Send to Waystar', value: 'send to waystar' },
  { name: 'Change Delivery Method', value: 'change delivery method' },
  { name: 'Delete Claim', value: 'delete claim' },
];

const columns = {
  ready: readyColumns,
  error: errorColumns,
  clearingHouse: clearingHouseColumns,
  sendtowaystarinprogress: sendToWaystarInProgressColumns,
  clearingHouseRejection: waystarRejectedColumns,
};

const isLoadingTrue = (args) => Object.values(args).some((item) => item);

const checkForElectronicClaim = (selectedClaims, deliveryMethod = 'paper') => selectedClaims.some(({ original }) => (original.deliveryMethodCode?.toLowerCase()?.includes(deliveryMethod))) && selectedClaims.some(({ original }) => (original.deliveryMethodCode?.toLowerCase()?.includes(enumMasterCode.Claim_Submission_Method_Electronic.toLowerCase())));

const masterCodes = [
  enumMasterCode.Claim_Error_All,
  enumMasterCode.Claim_Error_None,
  enumMasterCode.Claim_Submission_Method_Paper,
  enumMasterCode.Claim_Submission_Method_Electronic,
];

const enumNames = [
  enumConstants.ERROR_TYPE,
  enumConstants.SUBMISSION_METHOD,
];

const ClaimsTable = ({
  labels, setParentPath, ...props
}) => {
  const masterCodesWithId = useMasterId(masterCodes, enumNames);

  const {
    params, push, generatePath, replace,
  } = useRedirect();
  const [selectedClaims, setSelectedClaims] = useState({});
  const [notProcessingClaims, setNotProcessingClaims] = useState([]);
  const { claimFilter } = params;
  const [reFetchClaims, setReFetchClaims] = useState(() => {});
  const [massRebillModal, showMassRebillModal] = useState(false);
  const [activeColumns, setActiveColumns] = useState(claimFilter || 'ready');
  const [errorTypes, setErrorTypes] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [isAllClaimCheckSelected, setAllClaimCheckSelected] = useState(false);
  const [tableFilters, setTableFilters] = useState({});
  const [filtersData, setFiltersData] = useState({ FromDate: '', ToDate: '' });
  const tableRef = useRef();

  useEffect(() => {
    if (selectedClaims.length === 0) {
      setAllClaimCheckSelected(false);
    }
  }, [selectedClaims]);

  useEffect(() => {
    if (isAllClaimCheckSelected && notProcessingClaims.length === 0) {
      setNotProcessingClaims(selectedClaims);
    }
  }, [isAllClaimCheckSelected, selectedClaims]);

  const [isAssebleAllClaimsAuthenticated,
    isOverrideEditPermitted, isWaystarRejectedOverride,
  ] = useRights([
    rights.assemble_all_claims,
    rights.override_edits,
    rights.waystar_rejection_override,
  ]);

  const [
    claimPDFResponse,, claimPdfLoading,
    getClaimPDF,
    clearClaimPDF,
  ] = useCRUD({
    id: apiUrls.GET_CLAIMS_PDF,
    url: apiUrls.GET_CLAIMS_PDF,
    type: 'create',
  });

  const [
    claimPDFWithoutFormResponse,, claimPdfWithoutFormLoading,
    getClaimPDFWithoutForm,
    clearClaimPDFWithoutForm,
  ] = useCRUD({
    id: apiUrls.GET_CLAIMS_PDF_WITHOUT_FORM,
    url: apiUrls.GET_CLAIMS_PDF_WITHOUT_FORM,
    type: 'read',
  });

  const [
    billPatientResponse,,
    billingLoading,
    getBillPatient,
    clearBillPatient,
  ] = useCRUD({
    id: apiUrls.CLAIMS_BILL_PATIENT,
    url: apiUrls.CLAIMS_BILL_PATIENT,
    type: 'create',
  });

  const [markedResponse,,,
    markSubmitted,
    clearResponse,
  ] = useCRUD({
    id: apiUrls.MARK_AS_SUBMITTED_BY_CLIENT,
    url: apiUrls.MARK_AS_SUBMITTED_BY_CLIENT,
    type: 'create',
  });

  useEffect(() => {
    if (markedResponse) {
      clearResponse(true);
    }
  }, [markedResponse, clearResponse]);

  const column = columns[activeColumns];

  useEffect(() => {
    if (activeColumns) {
      setFiltersData((prevFiltersData) => ({
        ...prevFiltersData,
        state: activeColumns,
        ErrorTypeIds: claimFilter === 'error' ? `${errorTypes.toString()}` : '',
      }));
    }
  }, [activeColumns]);

  const { navigate: navigateToEditEncounter } = useTabLink({
    to: UiRoutes.editEncounterWithId,
  });

  const { navigate: navigateToEditPatient } = useTabLink({
    to: UiRoutes.editPatientWithTabId,
  });

  const ClaimsButtons = useCallback(({
    toggleMassRebillModal,
    onConfirm,
    handleViewPreview,
    makeAssembleClaims,
  }) => {
    const onSelect = (value, item) => {
      if (!(value?.length)) return null;
      if (!(selectedClaims.length)) {
        setSelectedOption(null);
        return Notification({ message: 'Please select at least one claim' });
      }
      setSelectedOption(value);
      if (value === 'print with form') {
        return handleViewPreview(selectedClaims.map(({ original }) => original?.claimId).join(','));
      }
      if (value === 'print without form') {
        return getClaimPDFWithoutForm({ ClaimIds: selectedClaims.map(({ original }) => original?.claimId).join(',') });
      }
      return ConfirmDialog({
        onOk: (close) => {
          onConfirm(value);
          close();
        },
        okText: 'Confirm',
        okButtonProps: {
          'data-testid': 'confirm-popup-ok-btn',
        },
        title: 'Warning',
        content: `Are you sure, you want to ${get(item, 'title', '')}?`,
        icon: <Icon name="ExclamationCircleOutlined" />,
      })();
    };

    const handleAssembleClicked = () => {
      const claimIds = selectedClaims.map(({ original }) => {
        const { claimId } = original;
        return claimId;
      });

      const requestParams = {
        data: {
          role: 'Billing',
          FromDate: filtersData.FromDate,
          ToDate: filtersData.ToDate,
          deliveryMethodId: tableFilters?.deliveryMethodId,
          SearchText: tableFilters?.SearchText,
        },
      };
      if (isEmpty(claimIds) || isAllClaimCheckSelected) {
        let notProcessing = [];
        if (!isEmpty(claimIds)) {
          const notProcessingClaimsIds = notProcessingClaims.map(({ original }) => {
            const { claimId } = original;
            return claimId;
          });
          notProcessing = difference(notProcessingClaimsIds, claimIds);
        }
        set(requestParams, 'data.SelectAllClaims', true);
        set(requestParams, 'data.NotSelectedClaims', notProcessing.join(','));
      } else {
        set(requestParams, 'data.claimIds', claimIds.join(','));
      }
      makeAssembleClaims(requestParams);
      tableRef?.current?.resetCheckBoxes?.(false);
      Notification({ message: SuccessMessages?.ASSEMBLE_CLAIM_IN_PROGRESS, success: true });
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleOverrideClicked = useCallback(() => {
      const claimIds = [];
      selectedClaims.forEach(({ original }) => {
        const { claimId } = original;
        claimIds.push(claimId);
      });

      ConfirmDialog({
        okText: 'Confirm',
        title: 'Warning',
        content: 'Are you sure you want to remove claim(s) from Waystar Rejection queue?',
        icon: <Icon name="ExclamationCircleOutlined" />,
        onOk: (close) => {
          try {
            markSubmitted({ data: { claimIds: claimIds.toString() } });
          } catch (error) {
            console.error('Error making API call:', error);
          }
          tableRef?.current?.resetCheckBoxes?.(false);
          close();
        },
      })();
    }, []);

    if (activeColumns === 'ready') {
      return (
        <div className="group-btns">
          {isAssebleAllClaimsAuthenticated && (
          <Button className="btn btn-success sm-btn" id="claims_add" data-testid="assemble-claims" onClick={handleAssembleClicked}>
            { isEmpty(selectedClaims) ? labels.get('buttons.assembleAllClaims') : labels.get('buttons.assembleSelectedClaims')}
          </Button>
          )}
        </div>
      );
    }
    if (activeColumns === 'error') {
      return (
        <div className="group-btns">
          <Button disabled className="cursor-not-allowed disabled-btn" id="claims_add" onClick={handleAssembleClicked}>
            { isEmpty(selectedClaims) ? 'Reassemble All Claims' : 'Reassemble Selected Claims'}
          </Button>
        </div>
      );
    }
    if (activeColumns === 'clearingHouseRejection') {
      return (
        <div className="group-btns">
          {isWaystarRejectedOverride && (
          <Button className="btn btn-success sm-btn" id="claims_add" data-testid="override-claims" onClick={handleOverrideClicked}>
            {(selectedClaims) && 'Remove From Waystar Rejected' }
          </Button>
          )}
        </div>
      );
    }
    if (activeColumns === 'clearingHouse') {
      return (
        <div className="flex group-btns">
          <div className="mr-rt-8">
            <Button className="btn btn-success sm-btn" id="claims_add" onClick={toggleMassRebillModal}>
              {labels.get('buttons.massRebill')}
            </Button>
          </div>
          <SelectBox
            options={options}
            placeholder="Select an option"
            value={selectedOption}
            name={null}
            selectProps={{
              onSelect,
              style: { minWidth: 200 },
              dropdownMatchSelectWidth: false,
            }}
            dataTestId="claims-select-btn"
          />
        </div>
      );
    }
    return <span />;
  }, [activeColumns, filtersData.FromDate, filtersData.ToDate, getClaimPDFWithoutForm,
    isAllClaimCheckSelected, isAssebleAllClaimsAuthenticated, labels, notProcessingClaims,
    selectedClaims, selectedOption, tableFilters]);

  const editPatient = useCallback((original) => () => {
    navigateToEditPatient({
      data: {
        name: `${original?.patientName} Demographic`,
      },
      ...params,
      id: original?.patientId,
      tab: 'demographic',
    });
  }, [navigateToEditPatient, params]);

  const editEncounter = useCallback((original) => () => {
    navigateToEditEncounter({
      data: {
        name: `${original?.patientName} Encounter`,
      },
      ...params,
      id: original?.patientId,
      encounterId: original?.billingEncounterId,
    });
  }, [navigateToEditEncounter, params]);

  const [
    submitClaimsResponse,,
    submitLoading,
    submitClaims,
    clearSubmitClaims,
  ] = useCRUD({
    id: apiUrls.SUBMIT_CLAIM,
    url: apiUrls.SUBMIT_CLAIM,
    type: 'update',
  });

  const handleViewPreview = useCallback((claimIds) => {
    getClaimPDF({ data: { claimIds, isClaimCorrected: false } });
  }, [getClaimPDF]);

  const handleBillPatient = useCallback((original) => () => {
    getBillPatient({ data: {} }, `/${original?.claimId}`);
  }, [getBillPatient]);

  const [
    deleteClaimResponse,,,
    deleteClaim,
    clearDeleteClaim,
  ] = useCRUD({
    id: apiUrls.DELETE_CLAIM,
    url: apiUrls.DELETE_CLAIM,
    type: 'delete',
    shouldClearError: false,
  });

  const [
    changeDeliveryMethodResponse,,
    changeMethodLoading,
    changeDeliveryMethod,
    clearChangeDeliveryMethod,
  ] = useCRUD({
    id: apiUrls.CHANGE_CLAIMS_DELIVERY_METHOD,
    url: apiUrls.CHANGE_CLAIMS_DELIVERY_METHOD,
    type: 'update',
  });

  useEffect(() => {
    if (changeDeliveryMethodResponse && !Array.isArray(changeDeliveryMethodResponse)) {
      clearChangeDeliveryMethod();
      reFetchClaims();
      setSelectedOption(undefined);
      tableRef?.current?.resetCheckBoxes?.(false);
    }
  }, [changeDeliveryMethodResponse]);

  useEffect(() => {
    if (deleteClaimResponse) {
      clearDeleteClaim(true);
      reFetchClaims();
    }
  }, [reFetchClaims, deleteClaimResponse, clearDeleteClaim]);

  const handleDeleteClaim = useCallback((row) => {
    const { claimId } = row;
    ConfirmDialog({
      onOk: (close) => {
        deleteClaim({}, `?ClaimIds=${claimId}`);
        Notification({ message: SuccessMessages.DELETION_OF_CLAIM_IS_IN_PROGRESS, success: true });
        setSelectedOption(undefined);
        tableRef?.current?.resetCheckBoxes?.(false);
        close();
      },
      title: 'Do you want to delete this item?',
      content: 'When clicked the OK button, item will be sent back to ready queue.',
      icon: <Icon name="ExclamationCircleOutlined" />,
    })();
  }, [deleteClaim]);

  const handleRightClickOptionClicked = useCallback(({ original }) => ({ key }) => {
    if (key === 'Delete claim') {
      handleDeleteClaim(original);
    }
  }, [handleDeleteClaim]);

  const ReadyQueueRightClickMenu = useCallback(({ original }) => () => {
    const handleViewPreviewClick = () => {
      handleViewPreview(original?.claimId);
    };

    const handleMenuItemClick = () => {
      const queryParams = {
        state: activeColumns,
        claimIds: original?.claimId,
        deliveryMethodId: original?.deliveryMethodCode?.toLowerCase()
          ?.includes(enumMasterCode.Claim_Submission_Method_Electronic.toLowerCase())
          ? masterCodesWithId?.Paper : masterCodesWithId?.Electronic,
        deliveryMethod: original?.deliveryMethodCode,
      };
      changeDeliveryMethod({}, `/UpdateDeliveryMethod?${createQueryParams(queryParams)}`);
    };

    return (
      <Menu className="profile-menu-container">
        <Menu.Item key="view preview" onClick={handleViewPreviewClick}>
          <span>View Preview</span>
        </Menu.Item>
        {isOverrideEditPermitted && (
        <>
          <Menu.Item key="Edit Patient" onClick={editPatient(original)}>
            <span>Edit Patient</span>
          </Menu.Item>
          <Menu.Item key="Edit Encounter" onClick={editEncounter(original)}>
            <span>Edit Encounter</span>
          </Menu.Item>
        </>
        )}
        <Menu.Item key="Create Attachment">
          <span>Create Attachment</span>
        </Menu.Item>
        <Menu.Item key="Change delivery method" onClick={handleMenuItemClick}>
          <span>Change delivery method</span>
        </Menu.Item>
      </Menu>
    );
  }, [changeDeliveryMethod, editEncounter, editPatient,
    handleViewPreview, isOverrideEditPermitted, masterCodesWithId]);

  const ErrorQueueRightClickMenu = useCallback(({ original }) => () => {
    const handleViewPreviewClick = () => {
      handleViewPreview(original?.claimId);
    };
    const handleMenuItemClick = () => {
      const queryParams = {
        state: activeColumns,
        claimIds: original?.claimId,
        deliveryMethodId: original?.deliveryMethodCode?.toLowerCase()
          ?.includes(enumMasterCode.Claim_Submission_Method_Electronic.toLowerCase())
          ? masterCodesWithId?.Paper : masterCodesWithId?.Electronic,
        deliveryMethod: original?.deliveryMethodCode,
      };
      changeDeliveryMethod({}, `/UpdateDeliveryMethod?${createQueryParams(queryParams)}`);
    };

    return (
      <Menu className="profile-menu-container">
        <Menu.Item onClick={handleViewPreviewClick} key="view preview">
          <span>View Preview</span>
        </Menu.Item>
        <Menu.Item onClick={editPatient(original)} key="Edit Patient">
          <span>Edit Patient</span>
        </Menu.Item>
        <Menu.Item onClick={editEncounter(original)} key="Edit Encounter">
          <span>Edit Encounter</span>
        </Menu.Item>
        <Menu.Item key="Bill Patient" onClick={handleBillPatient(original)}>
          <span>Bill Patient</span>
        </Menu.Item>
        <Menu.Item key="Create Attachment">
          <span>Create Attachment</span>
        </Menu.Item>
        <Menu.Item key="Change delivery method" onClick={handleMenuItemClick} data-testid="change-delivery-method">
          <span>Change delivery method</span>
        </Menu.Item>
      </Menu>
    );
  }, [changeDeliveryMethod, editEncounter, editPatient,
    handleBillPatient, handleViewPreview, masterCodesWithId]);

  const ClearingQueueRightClickMenu = useCallback((row) => () => {
    const handleMenuItemClick = ({ key }) => {
      if (key === 'Print with form') return handleViewPreview(row?.original?.claimId);
      if (key === 'Print without form') return getClaimPDFWithoutForm({ ClaimIds: row?.original?.claimId });
      if (key === 'Change delivery method') {
        const queryParams = {
          state: activeColumns,
          claimIds: row?.original?.claimId,
          deliveryMethodId: row?.original?.deliveryMethodCode?.toLowerCase()
            ?.includes(enumMasterCode.Claim_Submission_Method_Electronic.toLowerCase())
            ? masterCodesWithId?.Paper : masterCodesWithId?.Electronic,
          deliveryMethod: row?.original?.deliveryMethodCode,
        };
        changeDeliveryMethod({}, `/UpdateDeliveryMethod?${createQueryParams(queryParams)}`);
      }
      if (key === 'Mark as submitted') return submitClaims({ claimIds: row.original?.claimId });
      return handleRightClickOptionClicked(key);
    };

    return (
      <Menu className="profile-menu-container" onClick={handleRightClickOptionClicked(row)}>
        <Menu.Item key="Print with form" onClick={handleMenuItemClick}>
          <span>Print with form</span>
        </Menu.Item>
        <Menu.Item key="Print without form" onClick={handleMenuItemClick}>
          <span>Print without form</span>
        </Menu.Item>
        <Menu.Item key="Delete claim">
          <span>Delete claim</span>
        </Menu.Item>
        <Menu.Item key="Change delivery method" onClick={handleMenuItemClick}>
          <span>Change delivery method</span>
        </Menu.Item>
        {(row?.original?.deliveryMethodCode?.toLowerCase()?.includes('paper')) && (
        <Menu.Item key="Mark as submitted" onClick={handleMenuItemClick}>
          <span>Mark as submitted</span>
        </Menu.Item>
        )}
      </Menu>
    );
  }, [activeColumns, changeDeliveryMethod,
    getClaimPDFWithoutForm, handleRightClickOptionClicked,
    handleViewPreview, masterCodesWithId, submitClaims]);

  const WaystarQueueRightClickMenu = useCallback(({ original }) => () => {
    const handleViewPreviewClick = () => {
      handleViewPreview(original?.claimId);
    };

    return (
      <Menu className="profile-menu-container">
        <Menu.Item key="view preview" onClick={handleViewPreviewClick}>
          <span>View Preview</span>
        </Menu.Item>
        {isOverrideEditPermitted && (
          <Menu.Item key="Edit Encounter" onClick={editEncounter(original)}>
            <span>Edit Encounter</span>
          </Menu.Item>
        )}
      </Menu>
    );
  }, [changeDeliveryMethod, editEncounter, editPatient,
    handleViewPreview, isOverrideEditPermitted, masterCodesWithId]);

  const rightClickMenus = {
    ready: ReadyQueueRightClickMenu,
    error: ErrorQueueRightClickMenu,
    clearingHouse: ClearingQueueRightClickMenu,
    clearingHouseRejection: WaystarQueueRightClickMenu,
  };

  const rightClickMenu = rightClickMenus[activeColumns];

  const [
    assembleClaimsResponse,,, makeAssembleClaims, clearAssembleClaims,
  ] = useCRUD({
    id: apiUrls.ASSEMBLE_CLAIMS,
    url: apiUrls.ASSEMBLE_CLAIMS,
    type: 'create',
    shouldClearError: false,
  });

  const [
    sendToWayStarResponse,,,
    sendToWayStar,
    clearSendToWayStar,
  ] = useCRUD({
    id: apiUrls.SEND_TO_WAYSTAR,
    url: apiUrls.NEW_SEND_TO_WAYSTAR,
    type: 'create',
  });

  const handleWayStarClaims = useCallback((requestParams) => {
    sendToWayStar(requestParams);
      tableRef?.current?.resetCheckBoxes?.(false);
  }, [sendToWayStar]);

  useEffect(() => {
    if (claimPDFResponse && !Array.isArray(claimPDFResponse)) {
      window.open(claimPDFResponse?.pdfUrl);
      clearClaimPDF(true);
      setSelectedOption(undefined);
      tableRef?.current?.resetCheckBoxes?.(false);
    }
  }, [claimPDFResponse]);

  useEffect(() => {
    if (claimPDFWithoutFormResponse && !Array.isArray(claimPDFWithoutFormResponse)) {
      window.open(claimPDFWithoutFormResponse?.pdfUrl);
      clearClaimPDFWithoutForm(true);
      setSelectedOption(undefined);
      tableRef?.current?.resetCheckBoxes?.(false);
    }
  }, [claimPDFWithoutFormResponse]);

  useEffect(() => {
    if (billPatientResponse && !Array.isArray(billPatientResponse)) {
      reFetchClaims();
      clearBillPatient();
    }
  }, [billPatientResponse, clearBillPatient]);

  useEffect(() => {
    if (assembleClaimsResponse) {
      clearAssembleClaims(true);
      reFetchClaims();
    }
  }, [clearAssembleClaims, assembleClaimsResponse, reFetchClaims]);

  useEffect(() => {
    if (submitClaimsResponse) {
      Notification({ message: SuccessMessages.SUBMIT_CLAIM_SUCCESSFULLY, success: true });
      clearSubmitClaims();
      reFetchClaims();
      setSelectedOption(undefined);
      tableRef?.current?.resetCheckBoxes?.(false);
    }
  }, [clearSubmitClaims, submitClaimsResponse, reFetchClaims]);

  useEffect(() => {
    if (sendToWayStarResponse) {
      Notification({ message: sendToWayStarResponse?.message, success: true });
      clearSendToWayStar(true);
      reFetchClaims();
      setSelectedOption(undefined);
    }
  }, [sendToWayStarResponse, reFetchClaims, clearSendToWayStar]);

  useEffect(() => {
    if (markedResponse) {
      Notification({ message: markedResponse?.message, success: true });
      clearResponse(true);
      reFetchClaims();
    }
  }, [markedResponse, reFetchClaims, clearResponse]);

  const toggleErrorPopup = useCallback((original) => () => {
    if (activeColumns === 'error') {
      push(generatePath(UiRoutes.claimsErrorPopup, {
        ...params,
        errorTab: 'softEdits',
        claimNumber: original?.claimId,
        patientId: original?.patientId,
        encounterId: original?.billingEncounterId,
        providerId: original?.providerId,
      }));
    }
  }, [activeColumns, generatePath, params, push]);

  const onRowClick = debounce(useCallback(({ original: { claimId } }) => {
    if (activeColumns === 'ready' || activeColumns === 'clearingHouse' || activeColumns === 'clearingHouseRejection') {
      getClaimPDF({ data: { claimIds: claimId, isClaimCorrected: false } });
    }
  }, [activeColumns, getClaimPDF]), 500);

  const onChangeStatus = useCallback((e) => {
    const { target: { name } } = e;
    setActiveColumns(name);
    tableRef?.current?.resetCheckBoxes?.(false);
    replace(generatePath(UiRoutes.claimsFilters, { ...params, claimFilter: name }));
  }, [generatePath, params, replace]);

  const onChangeDateType = useCallback((dateObject = {
    dateFrom: '',
    dateTo: '',
  }) => {
    tableRef?.current?.resetCheckBoxes?.(false);
    setFiltersData((prevFiltersData) => ({
      ...prevFiltersData,
      FromDate: dateObject?.dateFrom,
      ToDate: dateObject?.dateTo,
    }));
  }, []);

  const toggleMassRebillModal = useCallback(() => {
    showMassRebillModal(!massRebillModal);
  }, [showMassRebillModal, massRebillModal]);

  const onChangeErrorType = useCallback((selectedErrorType) => {
    if (selectedErrorType.indexOf(masterCodesWithId?.ClaimsErrorTypeAll) > -1) {
      setErrorTypes([masterCodesWithId?.ClaimsErrorTypeAll]);
      setFiltersData((prevFiltersData) => ({
        ...prevFiltersData,
        ErrorTypeIds: `${[masterCodesWithId?.ClaimsErrorTypeAll].toString()}`,
      }));
    } else if (selectedErrorType.indexOf(masterCodesWithId?.ClaimsErrorTypeNone) > -1) {
      setErrorTypes([masterCodesWithId?.ClaimsErrorTypeNone]);
      setFiltersData((prevFiltersData) => ({
        ...prevFiltersData,
        ErrorTypeIds: `${[masterCodesWithId?.ClaimsErrorTypeNone].toString()}`,
      }));
    } else {
      setErrorTypes(selectedErrorType);
      setFiltersData((prevFiltersData) => ({
        ...prevFiltersData,
        ErrorTypeIds: `${selectedErrorType.toString()}`,
      }));
    }
    tableRef?.current?.resetCheckBoxes?.(false);
  }, [masterCodesWithId]);

  const SearchFilterCollection = FilterComponents([
    {
      type: 'search',
      filterProps: {
        placeholder: 'Search by Date of Service, Patient Name',
        name: 'SearchText',
        id: 'contract_filter_search',
        style: {
          minWidth: 340,
        },
      },
    },
  ]);
  const CustomClaimsTable = useMemo(() => withQuery({
    url: apiUrls.GET_CLAIMS_LIST,
    listId: listId.CLAIMS + activeColumns,
  })(), [activeColumns]);

  const onConfirm = useCallback((value) => {
    const requestParams = {
      FromDate: filtersData.FromDate,
      ToDate: filtersData.ToDate,
      SearchText: tableFilters?.SearchText,
      deliveryMethodId: tableFilters?.deliveryMethodId,
    };
    let notProcessing = [];
    const claimIds = selectedClaims.map(({ original: { claimId } }) => claimId);
    if (isEmpty(claimIds) || isAllClaimCheckSelected) {
      if (!isEmpty(claimIds)) {
        const notProcessingClaimsIds = notProcessingClaims.map(
          ({ original: { claimId } }) => claimId,
        );
        notProcessing = difference(notProcessingClaimsIds, claimIds);
      }
      set(requestParams, 'SelectAllClaims', true);
      set(requestParams, 'NotSelectedClaims', notProcessing?.join(','));
    } else {
      set(requestParams, 'claimIds', claimIds.join(','));
    }
    if (value === 'mark as submitted') {
      if (!(checkForElectronicClaim(selectedClaims, 'electronic'))) {
        return submitClaims(requestParams);
      }
      return Notification({ message: ErrorMessages.MARK_AS_SUBMITTED_ERROR });
    }
    if (value === 'send to waystar') {
      if (isEmpty(claimIds) && !isAllClaimCheckSelected) {
        Notification({ message: ErrorMessages.PLEASE_SELECT_ATLEASET_1_CLAIM });
        return null;
      }
      if (checkForElectronicClaim(selectedClaims)) {
        return Notification({ message: ErrorMessages.SEND_TO_WAY_STAR_ERROR });
      }
      const requestParamsForWaystar = {
        data: {
          role: 'Billing',
          ...requestParams,
        },
      };
      return handleWayStarClaims(requestParamsForWaystar);
    }
    if (value === 'change delivery method') {
      if (!(checkForElectronicClaim(selectedClaims))) {
        // Sending not processing claims ids.
        const deliveryMethodId = selectedClaims[0].original.deliveryMethodCode?.toLowerCase()
        ?.includes(enumMasterCode.Claim_Submission_Method_Electronic.toLowerCase())
          ? masterCodesWithId?.Paper : masterCodesWithId?.Electronic;
        if (requestParams.SearchText === undefined) {
          set(requestParams, 'SearchText', '');
        }
        set(requestParams, 'deliveryMethodId', deliveryMethodId);
        set(requestParams, 'deliveryMethod', selectedClaims[0].original.deliveryMethodCode);
        set(requestParams, 'state', activeColumns);
        return changeDeliveryMethod({}, `/UpdateDeliveryMethod?${createQueryParams(requestParams)}`);
      }
      return Notification({ message: ErrorMessages.DELIVERY_METHOD_CHANGE });
    }
    const notProcessingClaimsIds = notProcessing?.length ? `&notSelectedClaims=${notProcessing?.join(',')}` : '';
    const deleteQueryParams = isAllClaimCheckSelected ? `?DeleteAllClaims=true&deliveryMethodId=${tableFilters?.deliveryMethodId}${notProcessingClaimsIds}&SearchText=${tableFilters?.SearchText || ''}&FromDate=${filtersData?.FromDate}&ToDate=${filtersData?.ToDate}` : `?ClaimIds=${claimIds.join(',')}`;
    deleteClaim({}, deleteQueryParams);
    Notification({ message: SuccessMessages.DELETION_OF_CLAIM_IS_IN_PROGRESS, success: true });
    setSelectedOption(undefined);
    tableRef?.current?.resetCheckBoxes?.(false);
    return null;
  }, [filtersData, tableFilters, selectedClaims, isAllClaimCheckSelected, deleteClaim,
    notProcessingClaims, submitClaims, handleWayStarClaims, changeDeliveryMethod,
    masterCodesWithId]);

  const commonOnChangeMethods = useCallback((filters) => {
    setTableFilters({ ...tableFilters, ...filters });
    setNotProcessingClaims([]);
    setAllClaimCheckSelected(false);
      tableRef?.current?.resetCheckBoxes?.(false);
  }, [tableFilters]);

  const debounceCommonOnChangeMethods = useMemo(() => debounce(commonOnChangeMethods, 1000),
    [commonOnChangeMethods]);

  const handleOnDeliveryMethodChange = useCallback((onFilterChange) => (e) => {
    const filters = {
      deliveryMethodId: e?.deliveryMethodId ? e.deliveryMethodId : undefined,
    };
    debounceCommonOnChangeMethods(filters);
    onFilterChange(filters);
  }, [debounceCommonOnChangeMethods]);

  const handleOnSearchChange = useCallback((onFilterChange) => (e) => {
    const filters = {
      SearchText: e?.target?.value ? e?.target?.value : undefined,
    };
    debounceCommonOnChangeMethods(filters);
    onFilterChange(filters);
  }, [debounceCommonOnChangeMethods]);

  const ExpandedRowRender = ({ row }) => {
    const error = get(row, 'original.waystarRejectionError');
    const dataArray = error?.split(',,');

    if (dataArray) {
      return (
        <div className="custom-fixed-table-height">
          <ul style={{ marginLeft: '45px', marginTop: '20px' }}>
            {dataArray.map((item, index) => (
              <li style={{ color: 'orange', listStyleType: 'disc' }} key={index}>{item}</li>
            ))}
          </ul>
        </div>
      );
    }
    return (
      <div
        className="custom-no-data-text"
        style={{
          textAlign: 'center', margin: '10px',
        }}
      >
        {' '}
        <span>Error Not Found</span>
      </div>
    );
  };

  return (
    <>
      {isLoadingTrue({
        claimPdfLoading,
        claimPdfWithoutFormLoading,
        submitLoading,
        changeMethodLoading,
        billingLoading,
      }) && <Loader />}
      <CustomClaimsTable
        filters={filtersData}
        isRowClickable={claimFilter !== 'error'}
        columns={column?.(labels, toggleErrorPopup)}
        renderRowSubComponent={(p) => <ExpandedRowRender {...p} />}
        initialSort={initialSort}
        setSelectedRow={setSelectedClaims}
        noDataText="Claims not found"
        onRowClick={onRowClick}
        showRowSelection={activeColumns === 'clearingHouseRejection' ? isWaystarRejectedOverride : activeColumns !== 'sendtowaystarinprogress'}
        rowSelectionOnDropdown
        isRowSelectionOnDropdownSelected={isAllClaimCheckSelected}
        setIsRowSelectionOnDropdownSelected={setAllClaimCheckSelected}
        rightClickMenu={rightClickMenu}
        footer={activeColumns === 'clearingHouse'}
        rowSelectionRef={tableRef}
        checkedRowIds={selectedClaims}
        skipInitialFetch
        {...props}
      >
        {({
          Component, initialFilters, onFiltersChange, reFetch, data,
        }) => (
          <FilterManager initialFilters={initialFilters} onChange={onFiltersChange}>
            {({ onFilterChange, filters }) => (
              <>
                <div className="heading-area flex no-componet-mr">
                  {TypeFilters.map((value, index) => (
                    <button
                      type="button"
                      name={value.name}
                      className={classNames('btn btn-primary sm-btn filter-button', claimFilter === value.name ? 'active' : '')}
                      key={index + 1}
                      onClick={(e) => {
                        onChangeDateType();
                        onChangeStatus(e);
                      }}
                    >
                      {value.placeholder}
                    </button>
                  ))}
                  <TypeFilterCollection
                    onFilterChange={handleOnDeliveryMethodChange(onFilterChange)}
                    filters={filters}
                  />
                </div>
                <div className="flex justify-content-sp-bt">
                  <div className="claim-filter">
                    <div className="feild-row" data-testid="claims-date-search-filters">
                      <SearchFilterCollection
                        onFilterChange={handleOnSearchChange(onFilterChange)}
                        filters={filters}
                      />
                      <div className="feild-row">
                        <DateFilter onChange={onChangeDateType} />
                      </div>
                      {activeColumns === 'error'
                       && (
                       <div className="mr-left-20">
                         <Select
                           value={errorTypes}
                           name={null}
                           inputSpan="24"
                           labelSpan="0"
                           enumName={enumConstants.ERROR_TYPE}
                           onChange={onChangeErrorType}
                           selectProps={{
                             style: { minWidth: 120 },
                             showArrow: true,
                             maxTagCount: 1,
                             mode: 'multiple',
                             placeholder: 'Error Types',
                           }}
                           component={SelectBox}
                         />
                       </div>
                       )}
                    </div>
                  </div>
                  <ClaimsButtons
                    toggleMassRebillModal={toggleMassRebillModal}
                    onConfirm={onConfirm}
                    handleViewPreview={handleViewPreview}
                    makeAssembleClaims={makeAssembleClaims}
                  />
                </div>
                {/* <Form form={form}> */}
                <TableWrapper
                  Component={Component}
                  reFetch={reFetch}
                  setReFetch={setReFetchClaims}
                  data={data}
                  setSelectedClaims={setSelectedClaims}
                  isAllClaimCheckSelected={isAllClaimCheckSelected}
                />
                {/* </Form> */}
              </>
            )}
          </FilterManager>
        )}
      </CustomClaimsTable>
      <MassRebill
        labels={labels}
        visible={massRebillModal}
        toggleModal={toggleMassRebillModal}
        reFetch={reFetchClaims}
      />
      <Route path={UiRoutes.claimsErrorPopup}>
        <ErrorPopUp setParentPath={setParentPath} />
      </Route>
    </>
  );
};

export default ClaimsTable;
