import React, { useCallback, useState, useEffect } from 'react';

import Modals from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import Notification from '../../../../../components/Notification';
import WidgetLoader from '../../../../../components/WidgetLoader';

import Events from '../../../../../lib/events';
import ErrorMessages from '../../../../../lib/errorMessages';
import SuccessMessages from '../../../../../lib/successMessages';
import { labelPaths, listId } from '../../../../../lib/constants';
import { apiUrls } from '../../../../../api/constants';

import WithLabel from '../../../../../hoc/withLabel';
import useCRUD from '../../../../../hooks/useCRUD';

import PatientClaimTable from '../../../../../wiredComponents/CollectionClaimsTable';
import useRedirect from '../../../../../hooks/useRedirect';

const ChangeResponsibilityPopup = ({
  labels, isVisible, toggleModal, claimId, payerName, billingEncounterServiceLineId,
}) => {
  const [selectedRows, setSelectedRow] = useState([]);
  const { params } = useRedirect();
  const { financialId } = params;

  const [response, , loading, updateResponsibility, clear] = useCRUD({
    id: listId.CHANGE_PATIENT_RESPONSIBILITY,
    url: apiUrls.CHANGE_PATIENT_RESPONSIBILITY,
    type: 'update',
  });

  useEffect(() => {
    if (response) {
      Notification({
        message: SuccessMessages.PAYMENT_RESPONSIBILITY_CHANGED_SUCCESSFULLY,
        success: true,
      });
      Events.trigger(`reFetchFinancialDetail-${financialId}`);
      Events.trigger('refetch-charge-table');
      toggleModal();
      clear();
    }
  }, [response]);

  const changeResponsibilityHandler = useCallback(() => {
    if (!selectedRows?.length) {
      return Notification({ message: ErrorMessages.AT_LEAST_ONE_CLAIM_SELECTED });
    }
    return updateResponsibility({
      billingEncounterServiceLineIds: (
        selectedRows.map(({
          original,
        }) => original?.billingEncounterServiceLineId)
      ),
    }, `/${claimId}`);
  }, [claimId, selectedRows, updateResponsibility]);

  return (
    <Modals
      visible={isVisible}
      width={1000}
      toggleModal={toggleModal}
      className="change-responsibility-modal"
      footer={[
        <div className="group-btns" key="footer">
          <Button
            className="btn btn-success min-wt-86 inline"
            onClick={changeResponsibilityHandler}
            data-testid="changeResponsibilityButton"
          >
            {labels.get('buttons.changeResponsibility')}
          </Button>
          <Button className="btn min-wt-86" onClick={toggleModal}>{labels.get('buttons.cancel')}</Button>
        </div>,
      ]}
    >
      <div className="addition-header posi-relative">
        {loading && <WidgetLoader />}
        <div className="lookup sprite-img-before">
          <p>{labels.get('titles.changeResponsibility')}</p>
        </div>
      </div>
      <p className="current-payer">
        {`${labels.get('titles.currentPayerName')} ${payerName}`}
      </p>
      <p className="current-payer">
        Move Responsibility To : Patient
      </p>
      <PatientClaimTable
        labels={labels}
        claimNumber={claimId}
        showRowSelection
        setSelectedRow={setSelectedRow}
        checkedRowIds={selectedRows}
        initialFilters={{ billingEncounterServiceLineId, isBillPatient: true }}
      />
    </Modals>
  );
};

export default WithLabel(ChangeResponsibilityPopup, labelPaths.BILL_PATIENT_POPUP);

