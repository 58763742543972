import React, { useCallback } from 'react';
import { Form as AntdForm, Row, Col } from 'antd';
import moment from 'moment';

import SuccessMessages from '../../../../../../lib/successMessages';
import {
  UiRoutes, labelPaths, enums, formId, listIds,
} from '../../../../../../lib/constants';
import Events from '../../../../../../lib/events';

import { apiUrls } from '../../../../../../api/constants';

import useRedirect from '../../../../../../hooks/useRedirect';
import WithLabel from '../../../../../../hoc/withLabel';

import Modal from '../../../../../../components/Modal';
import Form from '../../../../../../components/Form';
import DatePicker from '../../../../../../components/Form/DatePicker';
import Checkbox from '../../../../../../components/Form/CheckBox';
import Notification from '../../../../../../components/Notification';

import EnumSelect from '../../../../../../wiredComponents/Enum/Select';
import UserAutoComplete from '../../../../../../wiredComponents/UserAutoComplete';

const parser = (values) => {
  const formValues = values;
  if (values?.followupInId) {
    delete formValues.followupDate;
  } else {
    formValues.followupDate = moment(values?.followupDate).format();
  }
  return {
    ...formValues,
    followupNotification: !!values?.followupNotification,
  };
};

const MarkForFollowUp = ({ labels, closeTab, savedNavigation }) => {
  const { push, generatePath, params } = useRedirect();
  const [form] = AntdForm.useForm();
  const { claimNumber, id, tabId } = params;

  const toggleModal = useCallback(() => {
    push(generatePath(UiRoutes.claimsCollections, { ...params }));
  }, [generatePath, push, tabId, params]);

  const onRequestComplete = useCallback(({ response }) => {
    if (response) {
      Notification({
        message: SuccessMessages.CLAIMS_FOLLOWUP_UPDATED_SUCCESSFULLY,
        success: true,
      });
      Events.trigger('refetch-claim-overview');
      push(generatePath(savedNavigation?.parent?.path, {}));
      closeTab(tabId, false);
    }
  }, [closeTab, tabId, toggleModal]);

  return (
    <Modal
      visible
      toggleModal={toggleModal}
      title={labels.get('titles.followUp')}
      onOk={form.submit}
    >
      <Form
        form={form}
        formId={formId.MARKUP_FOR_FOLLOWUP}
        listId={listIds.MARKUP_FOR_FOLLOWUP}
        section
        parser={parser}
        extraData={{ AssignToUserId: id, method: 'put' }}
        url={`${apiUrls.MARKUP_FOR_FOLLOWUP}/${claimNumber}`}
        onRequestComplete={onRequestComplete}
      >
        <Row>
          <Col span={12}>
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => (
                prevValues.followupDate !== currentValues.followupDate)}
            >
              {({ getFieldValue }) => (

                <EnumSelect
                  label={labels.get('labels.followUpIn')}
                  name="followupInId"
                  dataTestId="followupInId"
                  enumName={enums.CLAIM_FOLLOW_IN}
                  disabled={getFieldValue('followupDate')}
                />
              )}
            </AntdForm.Item>
          </Col>
          <Col span={12}>
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => (
                prevValues.followupInId !== currentValues.followupInId)}
            >
              {({ getFieldValue }) => (

                <DatePicker
                  dataTestId="followupDate"
                  name="followupDate"
                  labelSpan={3}
                  inputSpan={12}
                  disabled={getFieldValue('followupInId')}
                  disabledDate={(current) => current && (current < moment().subtract(1, 'days'))}
                />
              )}
            </AntdForm.Item>

          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <UserAutoComplete
              name="AssignToUserId"
              label={labels.get('labels.assignedTo')}
              placeholder="Select User"
              form={form}
              minCharLength={3}
              labelSpan={10}
              inputSpan={14}
              header={false}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <EnumSelect
              label={labels.get('labels.followUpReason')}
              name="folloupReasonId"
              enumName={enums.FOLLOW_UP_REASON}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Checkbox
              labelSpan={0}
              name="followupNotification"
              valuePropName="checked"
            >
              {labels.get('labels.sendAlert')}
            </Checkbox>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default WithLabel(MarkForFollowUp, labelPaths.MARK_FOLLOW_UP_POPUP);
