import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import withQuery from '../../../../../../hoc/withQuery/withQuery';
import useRedirect from '../../../../../../hooks/useRedirect';
import useCRUD from '../../../../../../hooks/useCRUD';

import { apiUrls } from '../../../../../../api/constants';
import Events from '../../../../../../lib/events';
import { listId, UiRoutes } from '../../../../../../lib/constants';
import SuccessMessages from '../../../../../../lib/successMessages';

import Button from '../../../../../../components/Button';
import Loader from '../../../../../../components/Loader';
import columns from './columns';
import Notification from '../../../../../../components/Notification';
import ConfirmDialog from '../../../../../../components/ConfirmDialog';
import Icon from '../../../../../../components/Icon';

const BillStatementTable = ({ labels, ...props }) => {
  const {
    params: { id: patientId, tabId }, params, generatePath, push,
  } = useRedirect();

  const [selectedRows, setSelectedRows] = useState([]);

  const [addToFaxQueueResponse, , addToFaxLoading, addToFax, clearFaxResponse] = useCRUD({
    id: listId.ADD_FAX_QUEUE_SHOPPING_CART,
    url: apiUrls.ADD_FAX_QUEUE_SHOPPING_CART,
    type: 'create',
  });

  const CustomBillStatementTable = useMemo(() => withQuery({
    url: apiUrls.GET_STATEMENTS,
    listId: `BILL_STATEMENT-${patientId}`,
  })(), [patientId]);

  // To maintain set state in tab change
  useEffect(() => {
    setSelectedRows([]);
  }, [tabId]);

  useEffect(() => {
    if (addToFaxQueueResponse?.isDuplicateExists) {
      ConfirmDialog({
        onOk: (close) => {
          addToFax({
            data: {
              FaxDocumentIds: selectedRows?.map(({ original }) => original?.statementId),
              faxSource: 'statement',
              patientId,
              includeDuplicate: true,
            },
          });
          close();
        },
        onCancel: (close) => {
          addToFax({
            data: {
              FaxDocumentIds: selectedRows?.map(({ original }) => original?.statementId),
              faxSource: 'statement',
              patientId,
              includeDuplicate: false,
            },
          });
          close();
        },
        okText: labels.get('buttons.ok'),
        title: labels.get('titles.warning'),
        content: labels.get('message.warningMessage'),
        icon: <Icon name="ExclamationCircleOutlined" />,
        cancelButtonProps: { className: 'ant-modal-continue-green-btn' },
        okButtonProps: { className: 'ant-modal-ok-white-btn' },
      })();
    } else if (addToFaxQueueResponse?.recordInserted > 0) {
      Notification({ message: SuccessMessages.DOCUMENT_ADDED_TO_FAX_SUCCESSFULLY, success: true });
      Events.trigger('get-patient-fax-document-count');
      clearFaxResponse(true);
    }
  }, [addToFaxQueueResponse]);

  const handleAddToFaxQueue = useCallback(() => {
    const statementIds = selectedRows?.map(({ original }) => original?.statementId);
    addToFax({
      data: {
        FaxDocumentIds: statementIds,
        faxSource: 'statement',
        patientId,
      },
    });
  }, [addToFax, patientId, selectedRows]);

  const onRowClick = useCallback(({ original }) => {
    if (original.statusName !== 'Submitted') {
      push(generatePath(UiRoutes.viewStatementsWithTabId,
        { ...params, statementId: original?.statementId }));
    }
  }, [generatePath, params, push]);

  return (
    <>
      {(addToFaxLoading) && <Loader />}
      <CustomBillStatementTable
        columns={columns(labels, params, generatePath)}
        filters={{
          PatientId: patientId,
          StatementStatus: 'StatementSubmitted',
          IncludeSentToWayStar: true,
        }}
        onRowClick={onRowClick}
        isRowClickable
        scrollId={`patient-bill-statement-${patientId}`}
        showRowSelection
        setSelectedRow={setSelectedRows}
        checkedRowIds={selectedRows}
        {...props}
      >
        {({ Component, totalCount }) => (
          <div>
            <div className="inline-right">
              {selectedRows?.length ? (
                <div>
                  <Button className="sm-btn" onClick={handleAddToFaxQueue}>{labels.get('buttons.addToFax')}</Button>
                </div>
              ) : null}
              <span className="total-count-wrapper mr-bt-8">
                {`Total Count: ${totalCount}`}
              </span>
            </div>
            {Component }
          </div>
        )}
      </CustomBillStatementTable>
    </>
  );
};

export default BillStatementTable;
