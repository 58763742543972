import React, { useCallback, useEffect, useState } from 'react';
import { Map, OrderedMap } from 'immutable';
import { Affix } from 'antd';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import isFunction from 'lodash/isFunction';

import ConditionalWrapper from '../ConditionalWrapper';

import encode from '../../lib/encode';
import useRedirect from '../../hooks/useRedirect';
import { setCurrentTab as SetCurrentTab } from '../../store/actions/genericAction';
import { editPath as EditPath, deletePath as DeletePath, deleteTabData as DeleteTabData } from '../../store/actions/navigation';

function SavedNavigationBar(props) {
  const {
    children,
    editSavedLink,
    navigation,
    parentPath,
    closeSavedLink,
    deleteTabData,
    setCurrentTab,
    // currentTab,
    rightChildren,
    tabsFilter,
    customPath,
    tabs,
    titleComp,
    affixHeader,
    affixProps,
  } = props;
  const {
    params: routerParams, push, location, path,
  } = useRedirect();
  const url = location.pathname;
  const navigate = useCallback((link, tabId) => {
    push(link);
    if (tabId) {
      setCurrentTab(tabId);
    }
  }, [push, setCurrentTab]);

  const [query, setQuery] = useState('');

  const tabModuleName = tabs.get('tabModuleName');
  const tabData = OrderedMap(navigation.get(tabModuleName)) || OrderedMap({});

  let savedNavigation = navigation.get(tabModuleName) && tabData;
  const savedNavigationMap = tabData;
  let savedNavigationArray = savedNavigationMap ? savedNavigationMap.keySeq().toJS() : [];

  const navigateToNextTab = useCallback((tabId) => {
    const indexOfTabId = savedNavigationArray.indexOf(tabId);
    if (savedNavigationArray.length === 1) {
      navigate(parentPath);
    } else if (indexOfTabId + 1 < savedNavigationArray.length && indexOfTabId === 1) {
      navigate(savedNavigation[savedNavigationArray[indexOfTabId + 1]].path,
        savedNavigationArray[indexOfTabId + 1]);
    } else {
      const toNavigation = savedNavigation[savedNavigationArray[indexOfTabId - 1]];
      navigate(toNavigation.path || parentPath, savedNavigationArray[indexOfTabId - 1]);
    }
  }, [navigate, parentPath, savedNavigation, savedNavigationArray]);

  const closeTab = useCallback((tabId, navigateToNext = true) => {
    if (navigateToNext) {
      navigateToNextTab(tabId);
    }
    closeSavedLink({ id: path, tabId, tabModuleName });
    deleteTabData({ tabId });
  }, [closeSavedLink, path, tabModuleName, deleteTabData, navigateToNextTab]);

  const handleQuery = useCallback((queryParameters) => {
    setQuery(queryParameters);
  }, []);

  if (savedNavigation) {
    const filteredSavedNavigation = {};
    savedNavigation = savedNavigation.toJS();
    if (tabsFilter) {
      Object.keys(savedNavigation).forEach((tabId) => {
        if (savedNavigation[tabId].parent || tabsFilter(savedNavigation[tabId], routerParams)) {
          filteredSavedNavigation[tabId] = savedNavigation[tabId];
        }
      });
      savedNavigation = filteredSavedNavigation;
      savedNavigationArray = Object.keys(filteredSavedNavigation);
    }
  } else {
    if (parentPath && parentPath !== path && parentPath !== customPath) {
      push(`${parentPath}?${encode(query)}`);
    }
    return (
      <div className="main-content-area">
        <div className="heading-area flex justify-content-sp-bt align-center claim-screen">
          <div className="rt-side-panel move-head-top">
            {titleComp && titleComp()}
          </div>
          {rightChildren}
        </div>
        <div>
          {isFunction(children) ? children({
            closeTab,
            handleQuery,
          }) : children}
        </div>
      </div>
    );
  }

  return (
    <div className="main-content-area">
      <ConditionalWrapper
        condition={!affixHeader}
        wrapper={(panel) => (
          <Affix className="saved-navigation-affixed-header" {...affixProps}>
            {panel}
          </Affix>
        )}
      >
        <div className="heading-area flex justify-content-sp-bt align-center">
          <div className="rt-side-panel move-head-top">
            <div className="clip-boxes-wrap">
              {savedNavigationArray && savedNavigationArray.map((tabId) => (
                <ClipBox
                  deleteTabData={deleteTabData}
                  closeSavedLink={closeSavedLink}
                  key={tabId}
                  navigateToNextTab={navigateToNextTab}
                  editSavedLink={editSavedLink}
                  routerParams={routerParams}
                  navigate={navigate}
                  url={url}
                  myData={savedNavigation[tabId]}
                  tabModuleName={tabModuleName}
                  path={path}
                  titleComp={titleComp}
                />
              ))}
            </div>
          </div>
          {rightChildren}
        </div>
      </ConditionalWrapper>
      <div>
        {isFunction(children) ? children({
          closeTab,
          handleQuery,
          savedNavigation,
        }) : children}
      </div>
    </div>

  );
}

SavedNavigationBar.defaultProps = {
  listName: '',
  children: [],
  editSavedLink: () => { /* This is intentional */ },
  navigation: Map(),
  parentPath: '',
  closeSavedLink: () => { /* This is intentional */ },
  deleteTabData: () => { /* This is intentional */ },
  setCurrentTab: () => { /* This is intentional */ },
  rightChildren: [],
  myPath: '',
  tabsFilter: null,
  affixHeader: false,
  affixProps: {},
};

SavedNavigationBar.propTypes = {
  listName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  editSavedLink: PropTypes.func,
  navigation: PropTypes.instanceOf(Object),
  parentPath: PropTypes.string,
  closeSavedLink: PropTypes.func,
  deleteTabData: PropTypes.func,
  setCurrentTab: PropTypes.func,
  rightChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  myPath: PropTypes.string,
  tabsFilter: PropTypes.func,
  affixHeader: PropTypes.bool,
  affixProps: PropTypes.objectOf(PropTypes.number),
};

function ClipBox(props) {
  const {
    myData,
    navigate,
    url,
    routerParams,
    editSavedLink,
    navigateToNextTab,
    closeSavedLink,
    deleteTabData,
    tabModuleName,
    titleComp,
    path,
  } = props;
  const changeNavigation = useCallback(() => {
    navigate(myData.path, myData.tabId);
  }, [myData, navigate]);

  const closeTab = useCallback((event) => {
    event.stopPropagation();
    if (url === myData.path) {
      navigateToNextTab(myData.tabId);
    }
    closeSavedLink({ ...myData, tabModuleName });
    deleteTabData({ tabId: myData.tabId });
  }, [url, myData, closeSavedLink, tabModuleName, deleteTabData, navigateToNextTab]);

  useEffect(() => {
    if ((myData.tabId === routerParams.tabId) || (!routerParams.tabId && myData.parent)) {
      editSavedLink({
        ...myData, tabModuleName, path: url, params: { ...myData.params, ...routerParams },
      });
    }
  }, [url]);

  const renderClipBox = useCallback(() => {
    if (myData.parent && titleComp) {
      return titleComp(changeNavigation, url === myData.path);
    }
    return (
      <div role="presentation" className={classnames('clipbox', { active: url === myData.path })} onClick={changeNavigation}>
        {myData.parent && <p className="back-arrow height-9" />}
        <span
          className="capitalize"
          title={myData?.params?.data?.name?.replace(/[&/\\#,+()$~%.":*?<>{}-]/g, ' ')}
        >
          { myData?.params?.data?.showFullTitle
            ? myData?.params?.data?.name
            : myData?.params?.data?.name?.replace(/[&/\\#,+()$~%.":*?<>{}-]/g, ' ')}
        </span>
        {!myData.parent && <span role="presentation" onClick={closeTab} className="close-clip green" />}
      </div>
    );
  }, [changeNavigation,
    closeTab, myData.params.data.name, myData.parent, myData.path, titleComp, url]);
  return (
    path === myData?.id && myData?.parent ? null : renderClipBox()
  );
}

ClipBox.defaultProps = {
  myData: {},
  navigate: () => { /* This is intentional */ },
  url: '',
  routerParams: {},
  editSavedLink: () => { /* This is intentional */ },
  navigateToNextTab: () => { /* This is intentional */ },
  closeSavedLink: () => { /* This is intentional */ },
  deleteTabData: () => { /* This is intentional */ },
};

ClipBox.propTypes = {
  myData: PropTypes.instanceOf(Object),
  navigate: PropTypes.func,
  url: PropTypes.string,
  routerParams: PropTypes.instanceOf(Object),
  editSavedLink: PropTypes.func,
  navigateToNextTab: PropTypes.func,
  closeSavedLink: PropTypes.func,
  deleteTabData: PropTypes.func,
};

const mapStateToProps = ({ navigation, tabs }) => ({
  navigation,
  currentTab: navigation && navigation.get('currentTab'),
  tabs,
});

const mapDispatchToProps = (dispatch) => ({
  editSavedLink: (linkDetails) => dispatch(EditPath(linkDetails)),
  closeSavedLink: (linkDetails) => dispatch(DeletePath(linkDetails)),
  setCurrentTab: (tabId) => dispatch(SetCurrentTab(tabId)),
  deleteTabData: (linkDetails) => dispatch(DeleteTabData(linkDetails)),
  clearCurrentTab: () => dispatch({ type: 'CLEAR_CURRENT_TAB' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedNavigationBar);
