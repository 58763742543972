import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { apiUrls } from '../../../../api/constants';
import useRedirect from '../../../../hooks/useRedirect';
import useCRUD from '../../../../hooks/useCRUD';
import useRights from '../../../../hooks/useRights';
import withQuery from '../../../../hoc/withQuery/withQuery';
import { UiRoutes, listIds } from '../../../../lib/constants';
import rights from '../../../../lib/rights';

import WidgetLoader from '../../../../components/WidgetLoader';

import SimpleChangeForm from './Components/simpleChargeForm';
import ChargeForm from './Components/chargeForm';
import InsurancePayment from './Components/insurancePaymentForm';
import InsuranceAdjustment from './Components/insuranceAdjustmentForm';
import PatientAdjustment from './Components/patientAdjustment';
import PatientPayment from './Components/patientPaymentForm';
import DefaultForm from './Components/DefaultForm';
import simpleChangeColumn from './Components/DetailFormColumns/simpleChargeColumn';
import chargeColumn from './Components/DetailFormColumns/chargeColumn';
import insurancePaymentColumn from './Components/DetailFormColumns/insurancePaymentColumn';
import insuranceAdjustmentColumn from './Components/DetailFormColumns/insuranceAdjustmentColumn';
import patientAdjustmentColumn from './Components/DetailFormColumns/patientAdjustmentColumn';
import withHoldColumn from './Components/DetailFormColumns/withHoldColumn';
import patientPaymentColumn from './Components/DetailFormColumns/patientPaymentColumn';
import WithHold from './Components/withHoldForm';

import RenderButtons from './Components/RenderButtons';
import Events from '../../../../lib/events';

const formMap = {
  'Simple Charge': SimpleChangeForm,
  Charge: ChargeForm,
  'Insurance Payment': InsurancePayment,
  'Insurance Payment (Reversed)': InsurancePayment,
  'Insurance Payment Reversal': InsurancePayment,
  'Insurance Adjustment': InsuranceAdjustment,
  'Insurance Adjustment Reversal': InsuranceAdjustment,
  'Insurance Adjustment (Reversed)': InsuranceAdjustment,
  'Patient Adjustment': PatientAdjustment,
  'Patient Adjustment (Reversed)': PatientAdjustment,
  'Patient Adjustment Reversal': PatientAdjustment,
  Withhold: WithHold,
  'Patient Payment': PatientPayment,
  'Patient Payment (Reversed)': PatientPayment,
  'Patient Payment Reversal': PatientPayment,
  'Charge (Reversed)': ChargeForm,
  'Charge Reversal': ChargeForm,
  'default form': DefaultForm,
};

const columnMap = {
  'Simple Charge': simpleChangeColumn,
  Charge: chargeColumn,
  'Insurance Payment': insurancePaymentColumn,
  'Insurance Payment (Reversed)': insurancePaymentColumn,
  'Insurance Payment Reversal': insurancePaymentColumn,
  'Insurance Adjustment': insuranceAdjustmentColumn,
  'Insurance Adjustment Reversal': insuranceAdjustmentColumn,
  'Insurance Adjustment (Reversed)': insuranceAdjustmentColumn,
  'Patient Adjustment Reversal': patientPaymentColumn,
  'Patient Adjustment (Reversed)': patientPaymentColumn,
  'Patient Adjustment': patientPaymentColumn,
  Withhold: withHoldColumn,
  'Patient Payment': patientPaymentColumn,
  'Patient Payment (Reversed)': patientPaymentColumn,
  'Patient Payment Reversal': patientPaymentColumn,
  'Patient Refund': patientPaymentColumn,
  'Charge (Reversed)': chargeColumn,
  'Charge Reversal': chargeColumn,
  'default columns': patientAdjustmentColumn,
};

const TableWrapper = ({
  Component, data, setIsTableDataPresent,
}) => {
  useEffect(() => {
    if (data?.length) {
      setIsTableDataPresent(true);
    }
  }, [data]);

  return Component;
};

const FinancialDetails = ({
  labels,
}) => {
  const { push, generatePath } = useRedirect();
  const { params } = useRedirect();
  const { id, financialId } = params;
  const [showReversal, setShowReversal] = useState(false);
  const [isTableDataPresent, setIsTableDataPresent] = useState(false);
  const [
    isReversePaymentAuthenticated,
    isReverseSimpleChargeAuthenticated,
    isReverseInsuranceAdjustmentAuthenticated,
    isReversePatientAdjustmentAuthenticated,
    isAddRefundAuthenticated,
    isEditProcedureAuthenticated,
  ] = useRights([
    rights.reverse_patient_payment,
    rights.reverse_simple_charge,
    rights.reverse_insurance_patient_adjustment,
    rights.reverse_patient_adjustment,
    rights.add_refund,
    rights.edit_submitted_procedures,
  ]);

  const [financialData, , loading, getFinancialDetail, clearFiancialDetails] = useCRUD({
    id: `financial-detail-${id}`,
    url: apiUrls.GET_FINANCIAL_DETAIL,
    type: 'read',
  });

  useEffect(() => {
    Events.on(`reFetchFinancialDetail-${financialId}`, `reFetchFinancialDetail-${financialId}`, () => {
      getFinancialDetail({ FinanceId: financialId, showReversal: false });
      setShowReversal(false);
      setIsTableDataPresent(false);
    });
    return () => Events.remove(`reFetchFinancialDetail-${financialId}`);
  }, [financialId]);

  useEffect(() => {
    if (id) {
      getFinancialDetail({ FinanceId: financialId, showReversal: false });
    }
  }, [id]);

  const selectedFinancialData = useMemo(() => financialData?.detailsLineItem
  || {}, [financialData]);

  const isReversalForm = useMemo(() => (
    selectedFinancialData?.transactionType === 'Patient Adjustment Reversal'
    || selectedFinancialData?.transactionType === 'Patient Adjustment (Reversed)'
    || selectedFinancialData?.transactionType === 'Patient Payment Reversal'
    || selectedFinancialData?.transactionType === 'Patient Payment (Reversed)'
    || selectedFinancialData?.transactionType === 'Insurance Adjustment Reversal'), [selectedFinancialData]);

  const goBack = useCallback(() => {
    clearFiancialDetails(true);
    push(generatePath(UiRoutes.editPatientWithTabId, { ...params }));
  }, [clearFiancialDetails, push, generatePath, params]);

  const ActiveForm = useMemo(() => formMap[selectedFinancialData?.transactionType ?? ''] || formMap['default form'], [selectedFinancialData]);
  const column = useMemo(() => columnMap[selectedFinancialData?.transactionType] || columnMap['default columns'], [selectedFinancialData]);

  const CustomTable = useMemo(() => withQuery({
    url: apiUrls?.GET_PATIENT_CHARGE_LIST,
    listId: `${listIds?.FINANCIAL_CHARGE_HISTORY}-${financialId}`,
    accessor: (data) => ({ result: data?.transactions ?? [] }),
  })(), [financialId]);

  return (
    <div className="posi-relative">
      {loading && <WidgetLoader />}
      <div className="pannel back-pannel mr-top-20">
        <div className="pannel-heading">
          <p className="back-arrow" id="patients_financial_detail_goBack" onClick={goBack} role="presentation">
            {labels.get('buttons.goBack')}
          </p>
        </div>
        <div className="pannel-body">
          {ActiveForm && (
          <ActiveForm
            financialDetail={financialData?.detailsLineItem || {}}
            type={selectedFinancialData?.transactionType}
            transactions={financialData?.transactions || []}
            isReversalForm={isReversalForm}
            isRefundForm={selectedFinancialData?.transactionType === 'Patient Refund' || selectedFinancialData?.transactionType === 'Insurance Refund'}
          />
          )}
          <RenderButtons
            type={selectedFinancialData?.transactionType}
            patientId={id}
            labels={labels}
            financialId={financialId}
            hasLineItems={!!financialData?.transactions?.length}
            isPaymentReversed={!!financialData?.detailsLineItem?.isPaymentReversed}
            encounterId={financialData?.detailsLineItem?.billingEncounterId}
            setShowReversal={setShowReversal}
            showReversal={showReversal}
            claimId={financialData?.detailsLineItem?.claimId}
            claimNo={financialData?.detailsLineItem?.claimNo}
            prevClaimId={financialData?.detailsLineItem?.previousClaimId}
            prevClaimNo={financialData?.detailsLineItem?.previousClaimNo}
            transactionTypeId={financialData?.detailsLineItem?.transactionTypeId}
            patientName={financialData?.detailsLineItem?.patientName}
            queueMasterCode={financialData?.detailsLineItem?.queueMasterCode}
            isEditable={financialData?.detailsLineItem?.isEditable}
            detail={{
              amount: financialData?.detailsLineItem?.amount,
              balance: financialData?.detailsLineItem?.balance,
              refund: financialData?.detailsLineItem?.refund,
            }}
            isReversePatientPaymentAuthenticated={isReversePaymentAuthenticated}
            isReverseSimpleChargeAuthenticated={isReverseSimpleChargeAuthenticated}
            isReverseInsuranceAdjustmentAuthenticated={isReverseInsuranceAdjustmentAuthenticated}
            isReversePatientAdjustmentAuthenticated={isReversePatientAdjustmentAuthenticated}
            isAddRefundAuthenticated={isAddRefundAuthenticated}
            isEditProcedureAuthenticated={isEditProcedureAuthenticated}
          />
          <div className="mr-top-20 financial-detail-table">
            {selectedFinancialData?.transactionType && (
            <CustomTable
              filters={{ FinanceId: financialId, showReversal }}
              columns={column && column(labels, financialData)}
              noDataText={labels.get('labels.dataNotFound')}
              scrollId={`financial-detail-table-${selectedFinancialData?.transactionType}`}
              customData={isTableDataPresent ? undefined : financialData?.transactions}
              skipInitialFetch
              footer
            >
              {({ Component, data }) => (
                <div>
                  <TableWrapper
                    Component={Component}
                    data={data}
                    setIsTableDataPresent={setIsTableDataPresent}
                  />
                </div>
              )}
            </CustomTable>
)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialDetails;
