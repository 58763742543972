import React, { useCallback, useEffect, useState } from 'react';
import { Form as AntdForm } from 'antd';
import moment from 'moment';
import omit from 'lodash/omit';
import find from 'lodash/find';

import { useSelector } from 'react-redux';
import {
  enums, formId, listIds,
} from '../../../../../../lib/constants';
import { apiUrls } from '../../../../../../api/constants';
import useRedirect from '../../../../../../hooks/useRedirect';
import SuccessMessages from '../../../../../../lib/successMessages';
import Events from '../../../../../../lib/events';

import Form from '../../../../../../components/Form';
import Modals from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Form/Input';
import Notification from '../../../../../../components/Notification';

import WiredSelect from '../../../../../../wiredComponents/Enum/Select';
import WiredSelectWithoutTab from '../../../../../../wiredComponents/Select/selectBoxV2';
import { getCrudDataWithOrWithoutTabId } from '../../../../../../store/selectors';
import { isPageRange } from '../../../../../../lib/util';

const parseInitialData = (formData = {}) => omit({ ...formData }, ['pageNo']);

const WorkList = ({
  labels,
  isVisible,
  toggle,
  formData,
  navigateToPostedClaims,
  totalNoOfPages,
  isWorkListWithClaimId,
  isAddClaimToWorkList,
  claimId,
  claimNo,
  isUnexpectedError,
  isElectronicBatch,
  remittancePaymentIdentifier,
  setNeedToScroll,
}) => {
  const {
    params: { financialId, tabId }, params,
  } = useRedirect();
  const [form] = AntdForm.useForm();
  const [defaultBusinessEntity, setDefaultBusinessEntity] = useState();
  const lineItemData = useSelector((state) => getCrudDataWithOrWithoutTabId(state, `${listIds.CLAIM_DETAILS}-insurance-payment-${financialId ?? ''}`, tabId));

  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const onRequestComplete = useCallback(() => {
    toggle();
    if (isAddClaimToWorkList) {
      Notification({
        success: true, message: SuccessMessages.REMITTANCE_ERROR_TRANSACTION_TO_WORKLIST,
      });
      Events.trigger(`refetchPostedClaimsError${params?.remittancePaymentId}`);
      Events.trigger('GET_AMOUNT_AFTER_POSTING_BATCH');
    } else {
      Notification({ success: true, message: SuccessMessages.WORKLIST_ADDED_SUCCESSFULLY });
      navigateToPostedClaims();
    }
    Events.trigger(`refetch-finance-view-entered-item-details${params?.remittancePaymentId}`);
    Events.trigger('refetchBatchDetails');
    setNeedToScroll(false);
  }, [isAddClaimToWorkList, navigateToPostedClaims, params, setNeedToScroll, toggle]);

  const fetchingComplete = useCallback((data) => {
    setDefaultBusinessEntity(find(data, { parentEntityId: null }));
  }, []);

  const handleOptionsParser = useCallback((optionData) => {
    if (isUnexpectedError) {
      const unexpectedErrorReasonId = optionData?.find(({ masterCode } = {}) => masterCode === 'UnexpectedRemittanceError');
      form.setFieldsValue({
        remittanceReasonId: unexpectedErrorReasonId?.masterId,
      });
    }
    return optionData;
  }, [form, isUnexpectedError]);

  useEffect(() => {
    if (defaultBusinessEntity) {
      form.setFieldsValue({
        businessEntityId: defaultBusinessEntity?.businessEntityId,
      });
    }
  }, [defaultBusinessEntity]);

  return (
    <Modals
      visible={isVisible}
      title={labels.get('titles.addToWorkList')}
      toggleModal={toggle}
      width={400}
      destroyOnClose
      footer={[
        <Button key="cancel_workList" onClick={toggle}>{labels.get('buttons.cancel')}</Button>,
        <Button key="add_workList" className="btn btn-success" onClick={handleSubmit}>{labels.get('buttons.add')}</Button>,
      ]}
    >
      <Form
        form={form}
        section
        formId={formId.REMITTANCE_INSURANCE_WORK_LIST}
        url={apiUrls.ADD_WORK_LIST}
        extraData={{
          batchId: params?.remittanceId,
          remittancePaymentId: params?.remittancePaymentId,
          checkDate: moment.utc(formData?.checkDate).local().format('MM-DD-YYYY hh:mm:ss a'),
          payerId: formData?.payerId,
          claimId,
          remittancePaymentIdentifier,
          claimNo,
        }}
        initialData={parseInitialData(formData)}
        onRequestComplete={onRequestComplete}
      >
        <WiredSelectWithoutTab
          required
          id="businessEntityId"
          label={labels.get('businessEntity')}
          url={apiUrls.GET_BUSINESS_ENTITY}
          name="businessEntityId"
          nameAccessor="entityName"
          valueAccessor="businessEntityId"
          onFetchComplete={fetchingComplete}
          disabled={params?.claimFilter === 'outbox'}
          selectProps={{
            showSearch: true,
            showArrow: true,
          }}
        />
        <div className="with-clear-wrapper">
          <WiredSelectWithoutTab
            id="provider"
            name="providerId"
            url={apiUrls.SCHEDULAR_PROVIDER_DROPDOWN}
            label={labels.get('provider')}
            placeholder="Select Provider"
            nameAccessor="providerName"
            valueAccessor="providerId"
            startCaseOptions
            selectProps={{
              maxTagCount: 1,
              style: { width: '100%', maxWidth: 215 },
              showSearch: true,
              dropdownMatchSelectWidth: 250,
              getPopupContainer: (trigger) => trigger?.parentNode,
            }}
            defaultSorting={false}
            initialValue={isWorkListWithClaimId ? lineItemData?.[0]?.providerId : undefined}
            dataTestId="providerId"
            disabled={params?.claimFilter === 'outbox'}
          />
        </div>
        <WiredSelectWithoutTab
          id="location"
          name="locationId"
          url={apiUrls.SCHEDULAR_LOCATION_DROPDOWN}
          label={labels.get('location')}
          placeholder="Select Location"
          nameAccessor="locationName"
          valueAccessor="locationId"
          startCaseOptions
          selectProps={{
            size: 'middle',
            style: { width: '100%', maxWidth: 215 },
            showSearch: true,
            dropdownMatchSelectWidth: 250,
            getPopupContainer: (trigger) => trigger?.parentNode,
          }}
          initialValue={isWorkListWithClaimId ? lineItemData?.[0]?.locationId : undefined}
          dataTestId="locationId"
          disabled={params?.claimFilter === 'outbox'}
        />
        <WiredSelect
          required
          label={labels.get('reason')}
          enumName={enums.WORKLIST_REASONS}
          optionParser={handleOptionsParser}
          name="remittanceReasonId"
          disabled={params?.claimFilter === 'outbox'}
        />
        <Input
          required
          label={labels.get('amount')}
          name="amount"
          numberWithTwoDecimalPlaces
          disabled={isAddClaimToWorkList || params?.claimFilter === 'outbox'}
          placeholder="Enter Amount"
        />
        <Input
          alphaNumericOnly
          required
          label={labels.get('checkNumber')}
          name="checkNumber"
          maxValueLength={50}
          placeholder="Enter check Number"
          disabled
        />
        <Input
          required={!isElectronicBatch}
          label={labels.get('eobPageNumber')}
          name="pageNumber"
          placeholder="Enter EOB Page Number"
          disabled={params?.claimFilter === 'outbox'}
          rules={[
            ({ getFieldValue }) => isPageRange(getFieldValue('pageNumber'), labels.get('eobPageNumber'), totalNoOfPages),
          ]}
        />
      </Form>
    </Modals>
  );
};
export default WorkList;
